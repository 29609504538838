function initIndex() {
  const swiper = new Swiper('.main-page__promo-swiper', {
    direction: 'horizontal',
    loop: 0,
    navigation: {
      nextEl: '.main-page__promo-bot .swiper-button-next',
      prevEl: '.main-page__promo-bot .swiper-button-prev',
    },
    scrollbar: {
      el: '.main-page__promo-bot .swiper-scrollbar',
    },
    slidesPerView: 4,
    spaceBetween: 20,
    breakpoints: {
      1140: {
        slidesPerView: 4,
      },
      998: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    },
  })
}

export { initIndex }
