function initHeader() {
  const headerCourses = document.getElementById('header__courses')
  const catalogContainer = document.querySelector('.catalog-container')

  if (headerCourses) {
    headerCourses.addEventListener('click', (e) => {
      e.stopPropagation();
      catalogContainer.classList.toggle('is-active')
      document.body.classList.toggle('scroll-lock')
    })
  }

  const mobileSearchBtn = document.querySelector(".search_mobile");
	const mobileHeaderClose = document.querySelector(".header__close-search-btn");
	const mobileSearchHeader = document.querySelector(".mobile-search-header");
	const mobileHeaderForm = document.querySelectorAll(".header__search-wrapper")[1];
	const header = document.querySelector(".header");

	var main = document.querySelector("body main");

	function closeMobileSearch() {
	    header.style.display = "flex";
	    mobileSearchHeader.style.display = "none";
	    mobileHeaderForm.classList.remove("flex");

	    if (main) {
	        main.classList.remove("margin-short");
	    }
	}

	function showMobileSearch() {
    header.style.display = "none";
    mobileSearchHeader.style.display = "flex";
    mobileHeaderForm.classList.add("flex");

    if (main) {
        main.classList.add("margin-short");
    }

    let mobileInput = document.querySelector(".js-course-search");
    mobileInput.focus();
	}

	if (mobileSearchBtn) {
		mobileSearchBtn.addEventListener("click", showMobileSearch);
	}

	if (mobileHeaderClose) {
		mobileHeaderClose.addEventListener("click", closeMobileSearch);
	}
}

export { initHeader }
