function initSchool() {
  const filters = document.querySelector('.open-filters')

  if (filters) {
    const toggleFilters = filters.querySelector('.select-btn')

    toggleFilters.addEventListener('click', () => {
      filters.classList.toggle('is-active')
    })
  }
}

export { initSchool }
