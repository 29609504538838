function initCookie() {
  const cookieNotification = document.querySelector('.cookie-notification');

  if (cookieNotification) {
    function checkCookieConsent() {
      const consent = localStorage.getItem('cookieConsent');
      if (!consent) {
        cookieNotification.style.display = 'block';
      }
    }
    function acceptCookies() {
      localStorage.setItem('cookieConsent', 'true');
      cookieNotification.style.display = 'none';
    }

    checkCookieConsent();
    document.querySelector('.cookie-notification button').addEventListener('click', acceptCookies);
  }
}

export { initCookie }
