function initCourse() {
  // Animations in welcome
  const spots = document.querySelectorAll(".welcome__bg-spot");

  if (spots.length) {
    function spotAnimation(spot) {
      const container = document.querySelector('.welcome');

      gsap.to(spot, {
        duration: 10, // продолжительность анимации в секундах
        ease: "none", // выберите тип easing для анимации
        repeat: -1, // повторяйте анимацию бесконечно
        yoyo: true, // не переключайте анимацию между началом и концом
        x: () => {
          // Возвращайте случайное значение X для пятна с хаотичным движением
          return Math.random() * (container.clientWidth / 2);
        },
        y: () => {
          // Возвращайте случайное значение Y для пятна с хаотичным движением
          return Math.random() * (container.clientHeight / 2);
        },
        rotation: () => {
          // Возвращайте случайное значение поворота для пятна с хаотичным движением
          return Math.random() * 360;
        },
        onComplete: () => {
          // Перезапускайте анимацию для пятна с новыми случайными значениями
          spotAnimation(spot)
        },
      });
    }

    spots.forEach((spot) => {
      spotAnimation(spot)
    });
  }

  // Header
  const courseHeader = document.querySelector('.course-page .header')

  function onScroll() {
    if (courseHeader) {
      if (window.scrollY > (window.innerHeight / 1.75)) {
        courseHeader.classList.add('is-active')
      } else {
        courseHeader.classList.remove('is-active')
      }
    }
  }

  window.addEventListener('scroll', onScroll)

  // Toggle favorite
  const courseCardFavorite = document.querySelector('.course-card__favorite');

  if (courseCardFavorite) {
    const courseId = courseCardFavorite.dataset.courseId;
    let favorite = Cookies.get(`favorite-${courseId}`) === 'true';

    function toggleFavorite() {
      favorite = !favorite;
      if (favorite) {
        Cookies.set(`favorite-${courseId}`, 'true', { expires: 30 })
        courseCardFavorite.classList.add('is-active')
      } else {
        Cookies.remove(`favorite-${courseId}`);
        courseCardFavorite.classList.remove('is-active')
      }
    }

    courseCardFavorite.addEventListener('click', toggleFavorite)
  }

  // Timer for sale
  const courseCardTimer = document.querySelector('.course-card__timer');

  if (courseCardTimer) {
    const saleEndDate = {
      d: Number(courseCardTimer.dataset.saleEndDateD),
      h: Number(courseCardTimer.dataset.saleEndDateH),
      i: Number(courseCardTimer.dataset.saleEndDateI),
      s: Number(courseCardTimer.dataset.saleEndDateS)
    }

    function convertToSeconds(days, hours, minutes, seconds) {
      return days * 86400 + hours * 3600 + minutes * 60 + seconds;
    }

    let secondsForSale = convertToSeconds(saleEndDate.d, saleEndDate.h, saleEndDate.i, saleEndDate.s)

    function pad(num) {
      return num.toString().padStart(2, '0');
    }

    function formattedTimer() {
      const days = pad(Math.floor(secondsForSale / 86400));
      const hours = pad(Math.floor((secondsForSale % 86400) / 3600));
      const minutes = pad(Math.floor((secondsForSale % 3600) / 60));
      const seconds = pad(secondsForSale % 60);
      return {days, hours, minutes, seconds};
    }

    function setTimerValues() {
      const {days, hours, minutes, seconds} = formattedTimer();
      const courseCardTimerValueItemValues = document.querySelectorAll('.course-card__timer-value-item-value');

      courseCardTimerValueItemValues[0].textContent = days;
      courseCardTimerValueItemValues[1].textContent = hours;
      courseCardTimerValueItemValues[2].textContent = minutes;
      courseCardTimerValueItemValues[3].textContent = seconds;
    }

    setInterval(() => {
      if (secondsForSale > 0) {
        secondsForSale--;
        setTimerValues()
      }
    }, 1000)
  }

  // Promocode
  const courseCardPromocodeBtn = document.querySelector('.course-card__promocode-btn');

  if (courseCardPromocodeBtn) {
    const courseCardPromocodePopup = document.querySelector('.course-card__promocode-popup')

    courseCardPromocodeBtn.addEventListener('click', () => {
      courseCardPromocodePopup.classList.toggle('is-active')
    })

    document.addEventListener('click', (e) => {
      if (!e.target.closest('.course-card__promocode')) {
        courseCardPromocodePopup.classList.remove('is-active')
      }
    })

    function copyPromocode($event) {
      navigator.clipboard.writeText('KURS M01')
        .then(() => {
          toastr.success('Код скопирован в буфер обмена')
        })
        .catch(err => {
          toastr.warning('Не удалось скопировать код')
        });
    }

    const courseCardPromocodePopupBtnCopy = document.querySelector('.course-card__promocode-popup-btn--white')
    courseCardPromocodePopupBtnCopy.addEventListener('click', copyPromocode)
  }

  // Share
  function share() {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        toastr.success('Ссылка скопирована в буфер обмена')
      })
      .catch(err => {
        toastr.warning('Не удалось скопировать ссылку')
      });
  }

  const courseCardFooterBtnShare = document.querySelector('.course-card__footer-btn:not(.btn--primary)')

  if (courseCardFooterBtnShare) {
    courseCardFooterBtnShare.addEventListener('click', share)
  }

  // Section more
  const moreBtns = document.querySelectorAll('[data-more-btn]')

  function toggleSectionMore(e) {
    const parent = e.target.closest('.more-parent') ?? e.target.closest('.section');

    if (parent) {
      let text;

      if (parent && parent.classList) {
        parent.classList.toggle('is-active')
        text = parent.querySelector('.more-text');
      }
      if (parent.length && parent[0].classList) {
        parent[0].classList.toggle('is-active')
        text = parent[0].querySelector('.more-text');
      }

      if (text && text.clientHeight === 80) {
        text.style.maxHeight = '3000px'
      } else {
        text.style.maxHeight = '80px'
      }
    }
  }

  if (moreBtns.length) {
    document.querySelectorAll('.more-parent').forEach(parent => {
      const text = parent.querySelector('.more-text');
      const btn = parent.querySelector('.more-btn');

      if (text.clientHeight < 80) {
        btn.style.display = 'none'
      } else {
        text.style.maxHeight = '80px'
      }
    })

    moreBtns.forEach((btn) => {
      btn.addEventListener('click', toggleSectionMore)
    })
  }

  // Sliders
  const sectionSwiper = new Swiper('.section__slider');
  const coursesMoreSlider = document.querySelector('.courses-more:not(.courses-more--history) .courses-more__slider')

  if (coursesMoreSlider) {
    const coursesMoreSlides = coursesMoreSlider.querySelectorAll('.course__slide')
    const coursesMoreSliderCounter = document.querySelector('.courses-more--other .courses-more__slider-counter')

    function otherCoursesSliderEvent(swiper) {
      coursesMoreSliderCounter.textContent = `${swiper.activeIndex + 1} / ${coursesMoreSlides.length}`
    }

    const coursesMoreSwiper = new Swiper(coursesMoreSlider, {
      spaceBetween: 20,
      slidesPerView: 3,
      navigation: {
        nextEl: '.courses-more--other .courses-more__slider-button--next',
        prevEl: '.courses-more--other .courses-more__slider-button--prev',
      },
      scrollbar: {
        el: '.courses-more--other .courses-more__slider-scrollbar',
        draggable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1.4,
          spaceBetween: 20,
          scrollbar: false
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
          scrollbar: {
            el: '.courses-more--other .courses-more__slider-scrollbar',
            draggable: true,
          }
        }
      }
    })
    coursesMoreSwiper.on('afterInit', (swiper) => {
      otherCoursesSliderEvent(swiper)
    })
    coursesMoreSwiper.on('slideChange', (swiper) => {
      otherCoursesSliderEvent(swiper)
    })

    otherCoursesSliderEvent(coursesMoreSwiper)

    const coursesHistorySlider = document.querySelector('.courses-more--history .courses-more__slider')
    const coursesHistorySlides = coursesHistorySlider.querySelectorAll('.course__slide')
    const coursesHistorySliderCounter = document.querySelector('.courses-more--history .courses-more__slider-counter')

    function historyCoursesSliderEvent(swiper) {
      coursesHistorySliderCounter.textContent = `${swiper.activeIndex + 1} / ${coursesHistorySlides.length}`
    }

    const coursesHistorySwiper = new Swiper(coursesHistorySlider, {
      spaceBetween: 20,
      slidesPerView: 3,
      navigation: {
        nextEl: '.courses-more--history .courses-more__slider-button--next',
        prevEl: '.courses-more--history .courses-more__slider-button--prev',
      },
      scrollbar: {
        el: '.courses-more--history .courses-more__slider-scrollbar',
        draggable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1.4,
          spaceBetween: 20,
          scrollbar: false
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
          scrollbar: {
            el: '.courses-more--history .courses-more__slider-scrollbar',
            draggable: true,
          }
        }
      }
    })
    coursesHistorySwiper.on('afterInit', (swiper) => {
      historyCoursesSliderEvent(swiper)
    })
    coursesHistorySwiper.on('slideChange', (swiper) => {
      historyCoursesSliderEvent(swiper)
    })

    historyCoursesSliderEvent(coursesHistorySwiper)
  }


  // Teachers slider
  const teachersSlider = document.querySelector('.teachers__slider')
  const teachersSlides = document.querySelectorAll('.teachers__slide')
  const teachersSliderCounter = document.querySelector('.teachers__slider-counter')

  if (teachersSlides.length) {
    teachersSlides.forEach((slide, i) => {
      slide.classList.add(`teachers__slide--style-${i + 1}`)
    })

    if (teachersSlides.length < 3) teachersSlider.classList.add('teachers__slider--small')

    function teachersSliderEvent(swiper) {
      teachersSliderCounter.textContent = `${swiper.activeIndex + 1} / ${teachersSlides.length}`
    }

    const teachersSwiper = new Swiper('.teachers__slider', {
      spaceBetween: 20,
      slidesPerView: 2,
      // modules: [Navigation, Grid, Scrollbar],
      navigation: {
        nextEl: '.teachers__slider-button--next',
        prevEl: '.teachers__slider-button--prev',
      },
      scrollbar: {
        el: '.teachers__slider-scrollbar',
        draggable: true,
      },
      grid: {
        rows: 2
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 16,
          grid: false,
          scrollbar: false
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
          grid: {
            rows: teachersSlides.length > 2 ? 2 : 1
          },
          scrollbar: {
            el: '.teachers__slider-scrollbar',
            draggable: true,
          }
        }
      }
    })
    teachersSwiper.on('afterInit', (swiper) => {
      teachersSliderEvent(swiper)
    })
    teachersSwiper.on('slideChange', (swiper) => {
      teachersSliderEvent(swiper)
    })

    teachersSliderEvent(teachersSwiper)
  }

  // Reviews
  const reviewsListTabmenuItems = document.querySelectorAll('.reviews__list-tabmenu-item')

  if (reviewsListTabmenuItems.length) {
    reviewsListTabmenuItems.forEach((item) => {
      item.addEventListener('click', function () {
        reviewsListTabmenuItems.forEach((item) => {
          item.classList.remove('is-active')
        })
        this.classList.add('is-active')
      })
    })
  }

  const reviewsListSortTabmenuItems = document.querySelectorAll('.reviews__list-sort-tabmenu-item')

  if (reviewsListSortTabmenuItems.length) {
    reviewsListSortTabmenuItems.forEach((item) => {
      item.addEventListener('click', function () {
        reviewsListSortTabmenuItems.forEach((item) => {
          item.classList.remove('is-active')
        })
        this.classList.add('is-active')
      })
    })
  }

  // Loader
  const loader = document.querySelector('.spinner-overlay')

  if (loader) {
    setTimeout(() => {
      loader.remove()
    }, 2000)
  }
}

export { initCourse }
