function initCatalog() {
  const filtersMobileBtn = document.querySelector('.filters-mobile-btn');
  const filtersMobileWrapper = document.querySelector('.filters-mobile-wrapper');
  const closeFilters = document.querySelector('.close-filters')

  if (filtersMobileBtn) {
    filtersMobileBtn.addEventListener('click', () => {
      filtersMobileWrapper.classList.toggle('active')
    })

    closeFilters.addEventListener('click', () => {
      filtersMobileWrapper.classList.toggle('active')
    })
  }
}

export { initCatalog }
